import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import SingleTag from './SingleTag';

const CaseCard = styled.div`
  border-radius: 16px;
  background: #ffffff;
  box-shadow: 5px 5px 15px #d9d9d9;
  padding: 0.6rem;
  padding-left: 1rem;
  margin-bottom: 1.5rem;
  margin-top: 2rem;
  transition: 0.2s;
  :hover {
    box-shadow: 20px 20px 60px #a4a4a4, -20px -20px 60px #ffffff;
    transform: translate(-3px, -3px);
    transition: 0.2s;
  }
`;

const TagContainer = styled.div`
  border: 1px solid black;
  border-radius: 1.2rem;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  display: inline-block;
  align-content: center;
  margin: 0.3rem;
`;

const CaseContainer = styled.div`
  min-height: 100vh;
`;
function SingleCase({ caseStudy }) {
  return (
    <Link className="none" to={`/case/${caseStudy.slug.current}`}>
      <CaseCard>
        {/* <span className="eyebrow">CASE</span> */}

        <h2 className="caseName">{caseStudy.name}</h2>
        {/* <p>{caseStudy.tags.map((tag) => tag.name).join(', ')}</p> */}

        <p>{caseStudy.description}</p>
        <p>
          {/* {caseStudy.tags.map((tag) => (
            <SingleTag tag={tag} />
          ))} */}
        </p>
      </CaseCard>
    </Link>
  );
}
export default function CaseList({ caseStudies }) {
  return (
    <CaseContainer>
      {caseStudies.map((caseStudy) => (
        <SingleCase key={caseStudy.id} caseStudy={caseStudy} />
      ))}
    </CaseContainer>
  );
}
