import { graphql } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import Nav from '../components/Nav';
import CaseList from '../components/CaseList';
import TagsFilter from '../components/TagFilter';
import SEO from '../components/SEO';

const WorkContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const WorkContainerSafe = styled.div`
  width: 80%;
  @media (max-width: 768px) {
    width: 100%;
  }
  padding-top: 1.5rem;
`;

export default function WorkPage({ data, pageContext }) {
  console.log(data);
  const caseStudies = data.cases.nodes;
  return (
    <>
      <SEO
        title={
          pageContext.tag
            ? `${
                pageContext.tag.charAt(0).toUpperCase() +
                pageContext.tag.slice(1)
              }`
            : `Work`
        }
      />

      <WorkContainer>
        <WorkContainerSafe>
          <h1 className="heavy">Work</h1>

          <h2 className="light subhead">
            I specialize helping organizations to build and operate digital
            platforms, and draw upon a big toolkit. I pride myself on finding
            ways to serve users while accomodating organizations’ operational
            and technical capabilities.
          </h2>
          <TagsFilter activeTag={pageContext.tag} />
          <CaseList caseStudies={caseStudies} />
        </WorkContainerSafe>
      </WorkContainer>
    </>
  );
}

export const query = graphql`
  query CaseQuery($tagRegex: String) {
    cases: allSanityCasestudy(
      sort: { fields: date, order: DESC }
      filter: { tags: { elemMatch: { name: { regex: $tagRegex } } } }
    ) {
      nodes {
        id
        name
        description
        slug {
          current
        }
        tags {
          name
          id
          slug {
            current
          }
        }
      }
    }
  }
`;
