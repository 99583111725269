import { Link, useScrollRestoration, useStaticQuery } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import SingleTag from './SingleTag';

const TagContainer = styled.div`
  // border: 2px solid red;
  padding-bottom: 1rem;
  padding-top: 1.25rem;
`;

export default function TagsFilter({ activeTag }) {
  const { tags, caseStudies } = useStaticQuery(graphql`
    query {
      tags: allSanityTag(sort: { order: ASC, fields: order }) {
        nodes {
          name
          id
          description
          slug {
            current
          }
        }
      }
      caseStudies: allSanityCasestudy {
        nodes {
          tags {
            name
            id
          }
        }
      }
    }
  `);

  const allTags = tags.nodes;

  // {"name":"all tags","id":"alltags","description":"alltags","slug":""}

  const tagScrollRestoration = useScrollRestoration(`page-component-tags`);

  return (
    <TagContainer>
      {activeTag ? (
        <Link to="/work" state={{ disableScrollUpdate: true }}>
          <SingleTag
            tag={{
              name: 'all tags',
              id: 'alltags',
              description: 'alltags',
              slug: '',
            }}
            activeTag={activeTag}
          />
        </Link>
      ) : (
        <Link to="/work" state={{ disableScrollUpdate: true }}>
          <SingleTag
            tag={{
              name: 'all tags',
              id: 'alltags',
              description: 'alltags',
              slug: '',
            }}
            activeTag="all tags"
          />
        </Link>
      )}
      {allTags.map((tag) => (
        <Link
          to={`/tag/${tag.slug.current}`}
          key={tag.id}
          state={{ disableScrollUpdate: true }}
        >
          <SingleTag tag={tag} activeTag={activeTag} />
        </Link>
      ))}
    </TagContainer>
  );
}

// state: {
//   disableScrollUpdate: true,
// }
