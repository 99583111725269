import React from 'react';
import styled, { keyframes } from 'styled-components';

const TagContainer = styled.div`
  border: 1px solid black;
  border-radius: 1rem;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  display: inline-block;
  align-content: center;
  margin: 0.3rem;
`;

const TagContainerActive = styled.div`
  border: 2px solid #2c5ee8;
  background-color: #2c5ee8;
  border-radius: 1.2rem;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  display: inline-block;
  align-content: center;
  margin: 0.3rem;
  cursor: pointer;
`;

export default function SingleTag({ tag, activeTag }) {
  return (
    <>
      {tag.name === activeTag ? (
        <TagContainerActive>
          <span className="eyebrow whitetext">{tag.name} </span>
        </TagContainerActive>
      ) : (
        <TagContainer>
          <span className="eyebrow">{tag.name} </span>
        </TagContainer>
      )}
    </>
  );
}

// export const CaseCard = styled.div`
//   margin-left: 12px;
//   margin-right: 12px;
//   background: #ffffff;
//   box-shadow: 1px 2px 3px;
//   padding: 0.8em;
//   margin-bottom: 12px;
//   min-width: 90%;
//   /* min-height: 280px; */

//   &:hover {
//     background: #eeeeee;
//     box-shadow: 3px 4px 5px;
//   }
//   &:active {
//     background: #ffffff;
//     box-shadow: none;
//   }
//   @media (min-width: 870px) {
//     width: 344px;
//   }
// `;
